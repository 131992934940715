var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contents"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"profile-content mb-50"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 mb-2 sticky"},[_c('div',{staticClass:"breadcrumb-main p-3",staticStyle:{"background-color":"#f4f5f7 !important"}},[_c('h4',{staticClass:"text-capitalize breadcrumb-title"},[_vm._v(" Bank Verification "),_c('ul',{staticClass:"atbd-breadcrumb nav"},[_c('li',{staticClass:"atbd-breadcrumb__item"},[_c('router-link',{attrs:{"to":"/"}},[_c('span',{staticClass:"la la-home"})]),_vm._m(0)],1),_vm._m(1),_vm._m(2)])])])]),_c('div',{staticClass:"container-fluid overlay-clipped"},[(!_vm.asyncLoader && _vm.loadingUserBankAccounts)?_c('LoaderOverlay',{staticStyle:{"height":"100%"},attrs:{"active":true,"zIndex":99,"position":"absolute"}}):_vm._e(),(_vm.noData)?_c('no-content'):_vm._e(),_c('div',{staticClass:"row"},[(_vm.banks.length)?_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"\n                  userDatatable\n                  orderDatatable\n                  sellerDatatable\n                  global-shadow\n                  border\n                  mb-30\n                  py-30\n                  px-sm-30 px-20\n                  bg-white\n                  radius-xl\n                  w-100\n                "},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table mb-0 table-borderless border-0"},[_vm._m(3),_c('tbody',_vm._l((_vm.banks),function(userAccount,k){return _c('tr',{key:k},[_c('td',[_c('div',{staticClass:"orderDatatable-title"},[_vm._v(" "+_vm._s(_vm.paginationData.offset + k + 1)+" ")])]),_c('td',[_c('div',{staticClass:"orderDatatable-title"},[_c('router-link',{attrs:{"to":{
                                name: 'officers-profile',
                                params: {
                                  officer: { ulid: userAccount.ulid },
                                },
                              }}},[_vm._v(" "+_vm._s(userAccount.name)+" ")])],1)]),_c('td',[_c('div',[_vm._v(_vm._s(userAccount.number))])]),_c('td',[_c('div',[_vm._v(_vm._s(_vm.getBankName(userAccount.bank)))])]),_c('td',[(userAccount.status == 1)?_c('div',{staticClass:"orderDatatable-title"},[_c('span',{staticClass:"atbd-tag tag-success tag-transparented"},[_vm._v("Approved")])]):_vm._e(),(userAccount.status == 0)?_c('div',{staticClass:"orderDatatable-title"},[_c('span',{staticClass:"atbd-tag tag-warning tag-transparented"},[_vm._v("Pending")])]):_vm._e(),(userAccount.status == -1)?_c('div',{staticClass:"orderDatatable-title"},[_c('span',{staticClass:"atbd-tag tag-danger tag-transparented"},[_vm._v("Declined")])]):_vm._e()]),_c('td',{staticClass:"text-right"},[_c('div',{staticClass:"slider-controller float-right w-30",class:{
                              'el-fade disabled': userAccount.status == 1,
                            },attrs:{"data-toggle":userAccount.status == 1 ? '' : 'modal',"data-target":"#modal-approve-reject","id":("slider-" + (userAccount.atlid))}},[_c('div',{staticClass:"\n                                custom-control custom-switch\n                                switch-primary switch-md\n                              "},[_c('input',{staticClass:"custom-control-input",attrs:{"type":"checkbox","id":userAccount.atlid},domProps:{"checked":userAccount.status == 1 ? true : false}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":userAccount.atlid},on:{"click":function($event){$event.preventDefault();return _vm.selectAtlid(userAccount, k)}}})])])])])}),0)])]),_c('div',{staticClass:"modal-info-delete modal fade show",attrs:{"id":"modal-approve-reject","tabindex":"-1","role":"dialog","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-sm modal-info",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_vm._m(4),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-danger btn-outlined btn-sm",attrs:{"type":"button","data-dismiss":"modal"},on:{"click":function($event){return _vm.approveUserBankAccount(-1)}}},[_vm._v(" Reject ")]),_c('button',{staticClass:"btn btn-success btn-outlined btn-sm",attrs:{"type":"button","data-dismiss":"modal"},on:{"click":function($event){return _vm.approveUserBankAccount(1)}}},[_vm._v(" Approve ")])])])])])])]):_vm._e()])],1),_c('Pagination',{attrs:{"url":_vm.bankUrl,"size":_vm.size},on:{"pageChange":_vm.pageChange,"loading":_vm.loading}})],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"breadcrumb__seperator"},[_c('span',{staticClass:"la la-slash"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"atbd-breadcrumb__item"},[_c('span',{staticClass:"bread-item",staticStyle:{"color":"#9299b8"}},[_vm._v("Verification")]),_c('span',{staticClass:"breadcrumb__seperator"},[_c('span',{staticClass:"la la-slash"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"atbd-breadcrumb__item"},[_c('span',{staticClass:"bread-item"},[_vm._v("Bank")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',{staticClass:"userDatatable-header"},[_c('th',{attrs:{"scope":"col"}},[_c('span',{staticClass:"userDatatable-title"},[_vm._v("No")])]),_c('th',{attrs:{"scope":"col"}},[_c('span',{staticClass:"userDatatable-title"},[_vm._v("Account Name")])]),_c('th',{attrs:{"scope":"col"}},[_c('span',{staticClass:"userDatatable-title"},[_vm._v("Account Number")])]),_c('th',{attrs:{"scope":"col"}},[_c('span',{staticClass:"userDatatable-title"},[_vm._v("Bank")])]),_c('th',{attrs:{"scope":"col"}},[_c('span',{staticClass:"userDatatable-title"},[_vm._v("Status")])]),_c('th',{staticClass:"text-right",attrs:{"scope":"col"}},[_c('span',{staticClass:"userDatatable-title"},[_vm._v("Action")])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"modal-info-body d-flex"},[_c('div',{staticClass:"modal-info-icon warning"},[_c('span',{attrs:{"data-feather":"info"}})]),_c('div',{staticClass:"modal-info-text"},[_c('h6',[_vm._v("Are you sure you want to approve")])])])])}]

export { render, staticRenderFns }